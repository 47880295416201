import React from "react";
import NavPagesHeader from "./NavPagesHeader";
import Navbar from "./Navbar";
import Footer from "./Footer";

function DataProtection() {
  return (
    <>
      <Navbar />
      <NavPagesHeader
        title="Data Protection Policy"
        path={"Home / DATA PROTECTION"}
      />
      <div className="d-flex flex-column w-90 mx-auto my-4">
        <h2
          className="heading-black mx-0 my-3 text-left"
          style={{ fontFamily: "Poppins" }}
        >
          Data Protection Policy
        </h2>
        <div className="text-grey">
          In the course of your work, you may come into contact with or use
          confidential information about employees, clients, customers and
          suppliers, for example their names and addresses. The Data Protection
          Act 1998 contains principles affecting employees’ and other personal
          records. Information protected by the Act includes not only personal
          data held on computer but also certain manual records containing
          personal data, for example employee personnel files that form part of
          a structured filing system. The purpose of this policy is to ensure
          that you do not breach the Act. If you are in any doubt what you can
          or cannot disclose and to whom, do not disclose the personal
          information until you have sought further advice from the Company’s
          Data Protection Officer or a Company Director. You should be aware
          that you are personally accountable for your actions, and you can be
          held criminally liable if you knowingly or recklessly disclose
          personal data in breach of the Act. A serious breach of Data
          Protection is also a disciplinary offence. If you access another
          employee’s personnel records without authority, this constitutes as
          gross misconduct and could lead to your summary dismissal. It is a
          condition of employment that employees abide by this policy and any
          failure to follow it can result in disciplinary proceedings.
          <br />
          1. Processed fairly and lawfully and must not be processed unless
          certain conditions are met in relation to personal data and additional
          conditions are met in relation to sensitive personal data. The
          conditions are either that the employee has given his consent to the
          processing, or the processing is necessary for the various purposes
          set out in the Act. Sensitive personal data may only be processed with
          the explicit consent of the employee and consists of information
          relating to:
          <br />
          • Race or ethnic origin
          <br />
          • Political opinions and trade union membership
          <br />
          • Religious or other beliefs
          <br />
          • Physical or mental health or condition
          <br />
          • Sexual life
          <br />
          • Criminal offences, both committed and alleged
          <br />
          2. Obtained only for one or more specified and lawful purposes and
          must not be processed in any manner incompatible with those purposes.
          <br />
          3. Adequate, relevant, and not excessive in relation to the purposes
          for which it is processed. The Company will review employees’
          personnel files on a regular basis to ensure they do not contain a
          backlog of out of date or irrelevant information and to check there is
          a sound business reason requiring information to continue to be held.
          <br />
          4. Accurate and, where necessary, kept up to date. If your personal
          information changes, for example you change address or get married and
          change your surname, you must inform your line manager as soon as
          reasonably practicable so that the Company’s records can be updated.
          The Company cannot be responsible for any such errors unless the
          employee has notified the Company of the relevant change.
          <br />
          5. Not kept for longer than is necessary. The Company will keep
          personnel files for no longer than six years after an employee has
          left the Company’s employment. Different categories of data will be
          retained for different periods of time, depending on legal,
          operational, and financial requirements. Any data which the Company
          decides it does not need to hold for a particular time will be
          destroyed after approximately one year. Data relating to unsuccessful
          job applicants will only be retained for a period of one year.
          <br />
          6. Processed in accordance with the rights of employees under the Act.
          <br />
          7. Secure. Appropriate technical and organisational measures must be
          taken against unauthorised or unlawful processing of personal data and
          against accidental loss or destruction of, or damage to, data.
          Personnel files are confidential and are stored as such in locked
          filing cabinets. Only authorised employees have access to these files.
          For a list of authorised employees, please contact Zoë Benham, the
          Company’s Data Protection Officer. Files will not be removed from
          their normal place of storage without good reason. Data stored on
          memory sticks, discs, portable hard drives or other removable storage
          media is kept in locked filing cabinets. Data held on computer is also
          stored confidentially by means of password protection, encryption or
          coding and again only the above employees have access to that data.
          The Company has network back-up procedures to ensure that data on
          computer cannot be accidentally lost or destroyed.
          <br />
          8. Not transferred to a country or territory outside the European
          Economic Area unless that country or territory ensures an adequate
          level of protection in relation to the processing of personal data.
          <br />
          <br />
          Employees’ consent to personal information being held
          <br />
          The Company holds personal data about its employees and, by signing
          your contract of employment, you have consented to that data about you
          being processed by the Company for any purpose related to your
          continuing employment or its termination including, but not limited
          to, payroll, human resources and business continuity planning
          purposes. Agreement to the Company processing your personal data is a
          condition of your employment. This includes giving your consent to the
          Company using your name, photograph and a brief work experience
          history in its marketing or promotional material, whether in hard copy
          print format or online on the Company website. It also includes
          supplying the Company with any personal data that it may request from
          you from time to time as necessary for the performance of your
          contract of employment or the conduct of the Company’s business, for
          example, supplying up to date contact telephone numbers to be held by
          line manager as part of its business continuity plan. The Company also
          holds limited sensitive personal data about its employees and, by
          signing this handbook, you give your explicit consent to our holding
          and processing that data, for example sickness absence records,
          particular health needs and equal opportunities monitoring data.
          <br />
          Employees’ rights to access personal information
          <br />
          Under the Act, employees have the right on request to receive a copy
          of the personal data that the Company holds about them, including
          personal data held on personnel files that form part of a relevant
          filing system, and to demand that any inaccurate data held be
          corrected or removed. They also have the right to seek compensation
          where damage and distress have been caused to them as a result of any
          breach of the Act by the Company. Employees have the right, on
          request.
          <br />
          • To be told by the Company whether and for what purpose personal data
          about them is being processed.
          <br />
          • To be given a description of the personal data concerned and the
          recipients to whom it is or may be disclosed.
          <br />
          • To have communicated in an intelligible form the personal data
          concerned, and any information available to the Company as to the
          source of the data.
          <br />
          • To be informed in certain circumstances of the logic involved in
          computerised decision-making.
          <br />
          Upon request, the Company will provide you with a statement regarding
          the personal data held about you. This will state all types of
          personal data and the Company holds and processes about you and the
          reasons for which they are processed. If you wish to access a copy of
          any personal data being held about you, you must make a written
          request for this and the Company reserves the right to charge you a
          fee of £10.00 for the supply of the information being requested. If
          you wish to make a request, please complete a personal data request
          form, which can be obtained from the Data Protection Officer. Once
          completed, it should be returned to the Data Protection Officer. The
          Company will respond promptly and in any case within 40 calendar days
          of receiving the request. Note that the Company will always check the
          identity of the employee making the request before processing it. If
          you wish to make a complaint that this policy has not been followed in
          respect of personal data the Company holds about you, you should raise
          the matter with the Data Protection Officer. If the matter is not
          resolved, it should be raised as a formal grievance under the Company
          grievance procedure.
          <br />
          Exemptions: There are a number of exemptions from the data protection
          regime set out in the Act, for example:
          <br />
          • Confidential references that are given, but not those received by
          the Company from third parties. Only designated managers can give
          Company references. Confidential references will not be provided
          unless the Company is sure this is the employee’s wish.
          <br />
          • Management forecasts and management planning (including documents
          setting out management plans for an employee’s future development and
          progress).
          <br />
          • Data which is required by law to be publicly available.
          <br />
          • Documents subject to legal professional privilege
          <br />
          Employees’ obligations in relation to personal information:
          <br />
          If, as part of your job duties and responsibilities, you collect
          personal information about employees or other people such as clients
          or customers, you must comply with this policy. This includes ensuring
          the information is processed in accordance with the Act, is only
          processed for the purposes for which it is held, is kept secure and is
          not kept for longer than necessary. You must also ensure you always
          comply with the following guidelines:
          <br />
          <br />
          Do not give out confidential personal information except to the data
          subject. In particular, it should not be given to someone, either
          accidentally or otherwise, from the same family or to any other
          unauthorised third party unless the data subject has given their
          explicit prior consent to this.
          <br />
          • Be aware that those seeking information sometimes use deception in
          order to gain access to it. Always verify the identity of the data
          subject and the legitimacy of the request, particularly before
          releasing personal information by telephone.
          <br />
          • Where the Company provides you with code words or passwords to be
          used before releasing personal information, for example by telephone,
          you must strictly follow the Company requirements in this regard.
          <br />
          • Only transmit personal information between locations by fax or email
          if a secure network is in place, for example, a confidential fax
          machine or encryption is used for email.
          <br />
          • If you receive a request for personal information about another
          employee, you should forward this to the Data Protection Officer, who
          will be responsible for dealing with such requests.
          <br />
          • Ensure that any personal data which you hold is kept securely,
          either in a locked filing cabinet or, if it is computerised, it is
          password protected so that it is protected from unintended destruction
          or change and it is not seen by any unauthorised persons.
          <br />
          • Do not access another employee’s records without authority as this
          will be treated as gross misconduct and it is a criminal offence.
          <br />
          • Do not write down (in electronic or hard copy form) opinions or
          facts concerning a data subject which it would be inappropriate to
          share with that data subject.
          <br />
          • Do not remove personal information from the workplace with the
          intention of processing it elsewhere unless this is necessary to
          enable you to carry out your job duties and has been authorised by
          your line manager.
          <br />
          • Ensure that, when working on personal information as part of your
          job duties and has been authorised by your line manager.
          <br />
          • Ensure that, when working on personal information as part of your
          job duties when away from your workplace and with the authorisation of
          your line manager, you continue to observe the terms of this policy
          and the Act, in particular the matter of data security.
          <br />
          • Ensure that hard copy personal information is disposed of securely,
          for example cross-shredded. Compliance with this Act is the
          responsibility of all employees. Any questions or concerns about the
          interpretations of this policy should be raised with the Data
          Protection Officer.
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DataProtection;
