import React from "react";
import TeamCard from "./TeamCard";
import team1 from "../../static/team_1.png";
import team2 from "../../static/team_2.png";
import team3 from "../../static/team_3.png";
import team4 from "../../static/team_4.png";
import mainImage from "../../static/main_team.png";

function TeamPreview({ setData }) {
  return (
    <div
      className="d-flex flex-wrap mx-auto my-5 justify-content-center"
      style={{ width: "90%" }}
    >
      <TeamCard
        img={team1}
        mainImg={mainImage}
        name={"Hellen Harison"}
        role={"Account Manager"}
        description={`Jassica Oliver is known for her ability to take a creative brief and run with it,
coming back with fresh ideas and a perfectly built design file every time. From
digital design to long-format layouts, she blends beautiful and intuitive with
each project she touches. She also happens to be the queen of deadline-
crushing, all while maintaining a can-do, Zen attitude that keeps the whole
Statement team centered.\n\nWhen he’s not building strong alliances with other creatives, project managers
and stakeholders alike, you’ll find him giving voice to client strategies with
fresh, compelling concepts and delightfully clever messaging.`}
        setData={setData}
        expertise={["Advisory", "Board, C-suite, Leadership"]}
        contact={"+44 (0) 1737 22 66 44"}
        mail={"greg.bell@morganprestwich.com"}
      />
      <TeamCard
        img={team2}
        mainImg={mainImage}
        name={"Cole Thompson"}
        role={"Developer"}
        description={`Jassica Oliver is known for her ability to take a creative brief and run with it,
coming back with fresh ideas and a perfectly built design file every time. From
digital design to long-format layouts, she blends beautiful and intuitive with
each project she touches. She also happens to be the queen of deadline-
crushing, all while maintaining a can-do, Zen attitude that keeps the whole
Statement team centered.\n\nWhen he’s not building strong alliances with other creatives, project managers
and stakeholders alike, you’ll find him giving voice to client strategies with
fresh, compelling concepts and delightfully clever messaging.`}
        setData={setData}
        expertise={["Advisory", "Board, C-suite, Leadership"]}
        contact={"+44 (0) 1737 22 66 44"}
        mail={"greg.bell@morganprestwich.com"}
      />
      <TeamCard
        img={team3}
        mainImg={mainImage}
        name={"Hellen Harison"}
        role={"Account Manager"}
        description={`Jassica Oliver is known for her ability to take a creative brief and run with it,
coming back with fresh ideas and a perfectly built design file every time. From
digital design to long-format layouts, she blends beautiful and intuitive with
each project she touches. She also happens to be the queen of deadline-
crushing, all while maintaining a can-do, Zen attitude that keeps the whole
Statement team centered.\n\nWhen he’s not building strong alliances with other creatives, project managers
and stakeholders alike, you’ll find him giving voice to client strategies with
fresh, compelling concepts and delightfully clever messaging.`}
        setData={setData}
        expertise={["Advisory", "Board, C-suite, Leadership"]}
        contact={"+44 (0) 1737 22 66 44"}
        mail={"greg.bell@morganprestwich.com"}
      />
      <TeamCard
        img={team4}
        mainImg={mainImage}
        name={"Jim Olsen"}
        role={"Ux Designer"}
        description={`Jassica Oliver is known for her ability to take a creative brief and run with it,
coming back with fresh ideas and a perfectly built design file every time. From
digital design to long-format layouts, she blends beautiful and intuitive with
each project she touches. She also happens to be the queen of deadline-
crushing, all while maintaining a can-do, Zen attitude that keeps the whole
Statement team centered.\n\nWhen he’s not building strong alliances with other creatives, project managers
and stakeholders alike, you’ll find him giving voice to client strategies with
fresh, compelling concepts and delightfully clever messaging.`}
        setData={setData}
        expertise={["Advisory", "Board, C-suite, Leadership"]}
        contact={"+44 (0) 1737 22 66 44"}
        mail={"greg.bell@morganprestwich.com"}
      />
      <TeamCard
        img={team2}
        mainImg={mainImage}
        name={"Hellen Harison"}
        role={"Account Manager"}
        description={`Jassica Oliver is known for her ability to take a creative brief and run with it,
coming back with fresh ideas and a perfectly built design file every time. From
digital design to long-format layouts, she blends beautiful and intuitive with
each project she touches. She also happens to be the queen of deadline-
crushing, all while maintaining a can-do, Zen attitude that keeps the whole
Statement team centered.\n\nWhen he’s not building strong alliances with other creatives, project managers
and stakeholders alike, you’ll find him giving voice to client strategies with
fresh, compelling concepts and delightfully clever messaging.`}
        setData={setData}
        expertise={["Advisory", "Board, C-suite, Leadership"]}
        contact={"+44 (0) 1737 22 66 44"}
        mail={"greg.bell@morganprestwich.com"}
      />
      <TeamCard
        img={team3}
        mainImg={mainImage}
        name={"Jim Olsen"}
        role={"Ux Designer"}
        description={`Jassica Oliver is known for her ability to take a creative brief and run with it,
coming back with fresh ideas and a perfectly built design file every time. From
digital design to long-format layouts, she blends beautiful and intuitive with
each project she touches. She also happens to be the queen of deadline-
crushing, all while maintaining a can-do, Zen attitude that keeps the whole
Statement team centered.\n\nWhen he’s not building strong alliances with other creatives, project managers
and stakeholders alike, you’ll find him giving voice to client strategies with
fresh, compelling concepts and delightfully clever messaging.`}
        setData={setData}
        expertise={["Advisory", "Board, C-suite, Leadership"]}
        contact={"+44 (0) 1737 22 66 44"}
        mail={"greg.bell@morganprestwich.com"}
      />
      <TeamCard
        img={team4}
        mainImg={mainImage}
        name={"Cole Thompson"}
        role={"Developer"}
        description={`Jassica Oliver is known for her ability to take a creative brief and run with it,
coming back with fresh ideas and a perfectly built design file every time. From
digital design to long-format layouts, she blends beautiful and intuitive with
each project she touches. She also happens to be the queen of deadline-
crushing, all while maintaining a can-do, Zen attitude that keeps the whole
Statement team centered.\n\nWhen he’s not building strong alliances with other creatives, project managers
and stakeholders alike, you’ll find him giving voice to client strategies with
fresh, compelling concepts and delightfully clever messaging.`}
        setData={setData}
        expertise={["Advisory", "Board, C-suite, Leadership"]}
        contact={"+44 (0) 1737 22 66 44"}
        mail={"greg.bell@morganprestwich.com"}
      />
      <TeamCard
        img={team1}
        mainImg={mainImage}
        name={"Cole Thompson"}
        role={"Developer"}
        description={`Jassica Oliver is known for her ability to take a creative brief and run with it,
coming back with fresh ideas and a perfectly built design file every time. From
digital design to long-format layouts, she blends beautiful and intuitive with
each project she touches. She also happens to be the queen of deadline-
crushing, all while maintaining a can-do, Zen attitude that keeps the whole
Statement team centered.\n\nWhen he’s not building strong alliances with other creatives, project managers
and stakeholders alike, you’ll find him giving voice to client strategies with
fresh, compelling concepts and delightfully clever messaging.`}
        setData={setData}
        expertise={["Advisory", "Board, C-suite, Leadership"]}
        contact={"+44 (0) 1737 22 66 44"}
        mail={"greg.bell@morganprestwich.com"}
      />
    </div>
  );
}

export default TeamPreview;
